var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"typography heading-two",staticStyle:{"margin":"2rem"}},[_vm._v("Utsendelser")]),(_vm.error)?_c('ErrorField',{attrs:{"error":_vm.error}}):(!_vm.$store.state.dispatches)?_c('Loading',{attrs:{"title":"Laster utsendelser","message":"Dette kan ta noen sekunder"}}):_c('div',[_c('v-card',{staticStyle:{"padding-bottom":"2rem"}},[_c('v-card-title',{staticClass:"typography heading-three"},[_vm._v(" Prosjekter "),_c('v-spacer'),_c('v-text-field',{staticStyle:{"margin-right":"1rem"},attrs:{"persistent-hint":"","label":"Søk i tabell","placeholder":"Søk i tabell","append-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"margin":"2rem"},attrs:{"headers":_vm.headers,"items":_vm.$store.state.dispatches,"items-per-page":5,"fixed-header":"","search":_vm.search,"custom-filter":_vm.customSearch,"loading":!_vm.$store.state.dispatches,"loading-text":"Laster data fra databasen "},scopedSlots:_vm._u([{key:"item.createdTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.createdTimestamp))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(_vm.translateStatus(item.status))+" ")])]}},{key:"item.handlinger",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"padding-right":"0.2rem"},attrs:{"medium":""},on:{"click":function($event){return _vm.editItem1(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_vm._v(" Rediger ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!item.template,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"padding-right":"0.2rem"},attrs:{"medium":""},on:{"click":function($event){return _vm.previewPDF(item)}}},[_vm._v(" mdi-note-search ")])],1)]}}],null,true)},[_vm._v(" Forhåndsvisning ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.onOpenMap(item)}}},[_vm._v(" mdi-map-search ")])],1)]}}],null,true)},[_vm._v(" Se Kart ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.archiveUrl}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.onOpenArchiveUrl(item.archiveUrl)}}},[_vm._v(" mdi-file-cabinet ")])],1)]}}],null,true)},[_vm._v(" Se arkiv ")])]}}],null,true)},[_c('v-alert',{staticClass:"tableNoResult",attrs:{"slot":"no-results","type":"error","rounded":"xl","value":true,"color":"#EB8380"},slot:"no-results"},[_vm._v(" Vi klarte ikke å finne noe i tabellen som matcher \""+_vm._s(_vm.search)+"\". ")])],1)],1)],1),(_vm.isEditingItem && _vm.editedItem)?_c('v-dialog',{attrs:{"value":true,"width":"80%"},on:{"click:outside":function($event){_vm.isEditingItem = false; _vm.editedItem = undefined}}},[_c('v-card',[_c('v-card-title',[_vm._v(" Rediger ")]),_c('v-card-text',[_c('DispatchEditor',{attrs:{"dispatchObject":_vm.editedItem},on:{"saved":function($event){_vm.editedItem = undefined},"close":function($event){_vm.editedItem = undefined}}})],1)],1)],1):_vm._e(),(_vm.isShowMap && _vm.editedItem)?_c('v-dialog',{attrs:{"width":"60%"},model:{value:(_vm.isShowMap),callback:function ($$v) {_vm.isShowMap=$$v},expression:"isShowMap"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Kart ")]),_c('v-card-text',[_c('Map',{attrs:{"polygons":_vm.editedItem.polygons,"height":"60vh"}})],1),_c('v-card-actions',{staticClass:"centerbtn",staticStyle:{"display":"flex","gap":"1rem"}},[_c('VTFKButton',{staticStyle:{"padding-bottom":"1rem"},attrs:{"type":"secondary","size":"small","passedProps":{ onClick: function () { return [_vm.isShowMap = false]; } }}},[_vm._v("Lukk ")])],1)],1)],1):_vm._e(),_c('v-alert',{staticClass:"v-alert-class",attrs:{"value":_vm.alert_success,"type":"success","color":"#91B99F","width":"50%","rounded":"xl","transition":"slide-y-transition"}},[_vm._v(" Statusen er lagret. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }