var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.error)?_c('ErrorField',{attrs:{"error":_vm.error}}):(!this.$store.state.templates)?_c('Loading',{attrs:{"title":"Laster maler"}}):_c('div',[_c('VDataTable',{attrs:{"items":this.$store.state.templates,"headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VToolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Maler")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('VTFKButton',{attrs:{"passedProps":{ onClick: function () { return _vm.openTemplateEditor(); }}}},[_vm._v("Ny mal")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.previewPDF(item)}}},[_vm._v(" mdi-file-find ")])],1)]}}],null,true)},[_vm._v(" Forhåndsvisning ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.openTemplateEditor(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_vm._v(" Rediger ")])]}}],null,true)})],1),(_vm.isShowEditor)?_c('VDialog',{staticStyle:{"text-align":"left"},attrs:{"width":"60%"},model:{value:(_vm.isShowEditor),callback:function ($$v) {_vm.isShowEditor=$$v},expression:"isShowEditor"}},[_c('VCard',[_c('VCardTitle',[_vm._v(" Endre mal ")]),_c('VCardText',[_c('TemplateEditor',{attrs:{"template":_vm.activeTemplate},on:{"close":function($event){return _vm.reset()},"saved":function($event){return _vm.reset()}}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }